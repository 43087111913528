@import '../../../assets/styles/utils/variables';

.filters {
   display: flex;
   justify-content: space-between;
   margin-top: 50px;

   .filtersLeft {
      display: flex;
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 5px;
      align-content: flex-start;
      align-items: flex-end;

      & > div {
         flex: 0 0 180px;
      }

      .filtersInput {
         width: 180px;
      }

      .multiSelect {
         svg {
            height: 20px;
            margin-left: 0.3rem;
            stroke: #0532f0;
         }

         button[class*='clear-selected-button'] {
            display: none !important;
         }
      }
   }

   .filtersRight {
      width: 20%;
      display: flex;
      column-gap: 5px;
      justify-content: flex-end;
      align-items: flex-end;

      button {
         flex: 0 0 100px;
      }
   }

   .multiSelect {
      width: 180px;
   }
}

.userDetailsModal {
   width: 700px !important;

   h2 {
      font-size: 20px;
   }

   .modalWrap {
      padding: 30px;
      p {
         font-size: 13px;
         .errorMessage {
            font-style: italic;
            font-weight: 500;
            color: #d85600;
         }
      }
   }

   .buttonWrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px 30px 20px 0px;
      & > * + * {
         margin-left: 20px;
      }
   }
}

.usersTable {
   margin-top: 60px;
   svg {
      cursor: pointer;
   }

   table {
      box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
      border-radius: 20px;
   }

   .totals {
      border-top: 15px solid rgba(215, 228, 246, 0.6);
      background-color: transparent;

      td {
         & > div {
            width: fit-content;
            div {
               width: 100%;
            }
            div {
               &:nth-child(2) {
                  text-align: right;
                  font-weight: bold;
                  font-size: 18px;
                  margin-top: 10px;
               }
            }
         }

         &:first-child {
            div {
               text-align: left !important;
            }
         }
      }
   }

   tr:last-child {
      border-bottom: none;
   }
}

.balanceInfo {
   margin-top: 40px;

   div {
      font-size: 1.1em;
      font-weight: bold;
   }
}

.messageWrap {
   margin: 30px 0;
   background-color: white;
   padding: 20px 10px 20px 10px;
   box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
   border-radius: 12px;
   width: 100%;
   font-size: 12px;
}

.chartsContainer {
   padding: 20px;
   margin-top: 50px;
   background-color: white;
   box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
   border-radius: 20px;

   canvas {
      width: 90% !important;
      margin: auto;
      margin-top: 20px;
   }

   .header {
      width: 90%;
      margin: auto;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      h2 {
         font-weight: 600;
         font-size: 22px;
      }
      p {
         margin-top: 5px;
         font-size: 13px;
         color: rgba(#25336b, 0.5);
      }

      .buttonSwitch {
         button {
            cursor: pointer;
            background-color: white;
            border: none;
            padding: 10px;
            font-weight: 600;
            font-size: 13px;
         }

         .leftButton {
            border-radius: 5px 0 0 5px;
         }
         .leftButton.disabled {
            border-right: none;
         }
         .rightButton.disabled {
            border-left: none;
         }
         .rightButton {
            border-radius: 0 5px 5px 0;
         }

         .active {
            border: 2px solid #0532f0;
            background-color: #0532f033;
            color: #0532f0;
         }

         .disabled {
            border: 2px solid #dddfe5;
            color: #dddfe5;
            background-color: white;
         }
      }
   }
}

.customDatepicker {
   width: 165px;
   height: 35px;
   outline: none;
   padding: 0px 15px;
   transition: all 0.2s ease;
   border: solid 2px #dcdee7;
   box-shadow: none;
   border-radius: 6px;
   font-size: 12px;
   color: #25316a;
   font-weight: 400;

   &:hover,
   &:focus {
      border: solid 2px #0532f0;
   }
}

.popperClass {
   button + div {
      padding: 5px 20px;
      & > div {
         &:first-child {
            background-color: white !important;
         }
         &:nth-child(2) {
            div {
               padding: 8px 15px;
               margin: 0 -10px;
            }
            & > * div {
               &:hover {
                  background-color: rgb(205, 214, 252);
                  border-radius: 15px;
               }
            }

            div[aria-current='date'],
            div[class*='react-datepicker__month-text--keyboard-selected'] {
               background-color: white;
               color: black !important;
               font-weight: 600;
               border-radius: 15px;
            }
            div[class*='react-datepicker__month-text'] {
               width: 5rem !important;
            }

            div[class*='react-datepicker__month-text--in-selecting-range'],
            div[class*='react-datepicker__month-text--in-range'] {
               background-color: rgb(205, 214, 252) !important;
               color: #0532f0 !important;
               font-weight: normal !important;
               border-radius: 0;
            }
            div {
               div[class*='react-datepicker__month-text--in-selecting-range'],
               div[class*='react-datepicker__month-text--in-range'] {
                  &:last-child {
                     border-radius: 0 15px 15px 0;
                  }
                  &:first-child {
                     border-radius: 15px 0 0 15px;
                  }
               }
            }

            div[aria-selected='true'],
            div[class*='react-datepicker__month-text--selecting-range-end'],
            div[class*='react-datepicker__month-text--range-start'],
            div[class*='react-datepicker__month-text--range-end'] {
               background-color: #0532f0 !important;
               color: white !important;
               border-radius: 15px !important;
               position: relative;
               z-index: 5;
            }
         }
      }
   }
}

.balanceTable {
   thead th,
   tbody td {
      padding: 20px 100px !important;
   }
   thead th span,
   tbody td:first-child {
      font-weight: 700;
      font-size: 14px;
   }

   thead th:nth-child(2),
   tbody td:nth-child(2) {
      text-align: right;
   }
}

.iconCell span {
   position: relative;

   &::after {
      position: absolute;
      content: url('../../../assets/images/svg/symbols.svg');
      width: 8px;
      height: 8px;
      right: -15px;
      top: 3px;
   }
}
