.sidebarLink {
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: relative;
   height: 70px;
   width: 100%;
}

.navLink {
   padding: 20px 0 20px 70px;
   font-size: 14px;
   font-weight: 400;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #fff;
   width: 270px;
   transition: all 0.2s;
   height: 70px;

   svg {
      position: absolute;
      left: 25px;
      transition: all 0.2s;
      fill: white;
      width: 24px;
      height: 24px;
   }

   &:hover {
      background-color: #ffd8be38;
   }
}
.active {
   transition: all 0.2s;
   font-size: 14px;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #0532f0;
   background-color: #cfddf1;
   height: 70px;
   svg {
      transition: all 0.2s;
      // path {
      //    fill: #0532f0;
      // }
   }
   &:hover {
      background-color: #cfddf1;
   }
}
