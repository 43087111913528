.error-state-container {
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   flex-direction: column;
   width: fit-content;
   max-width: 950px;
   margin: 0 auto;
   gap: 20px;

   .error-state-image {
      width: 85%;
      img {
         width: 100%;
      }
   }

   .error-state-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // gap: 25px;
      & > * + * {
         margin-top: 20px;
      }

      h2 {
         font-size: 18px;
         color: #212529;
         font-weight: 500;
         text-align: center;
         position: relative;
         z-index: 10;
      }
      h4 {
         font-size: 12px;
         font-weight: normal;
         box-sizing: border-box;
         width: 70%;
         text-align: center;
      }
   }

   .error-state-buttons-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      a,
      button {
         width: 150px;
      }
   }
}
