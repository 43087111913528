.sidebar {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 500;
   width: 70px;
   height: 100vh;
   overflow: hidden;
   background-color: #0532f0;

   padding: 20px 0;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   transition: all 0.2s;
   & > * + * {
      margin-top: 30px;
   }

   .logo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-left: 70px;
      transition: all 0.2s;

      h3 {
         font-size: 16px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.13;
         color: white;
         width: 40%;
      }
      a {
         position: absolute;
         left: 18px;
         &:hover {
            opacity: 0.8;
            transition: all 0.2s ease-in;
         }
      }
   }
}

.open {
   width: 270px;
   align-items: flex-start;
   transition: all 0.2s;
   box-shadow: 4px 0 20px 5px rgba(37, 49, 106, 0.2);
}

.toggleSidebar {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   position: relative;
   font-size: 14px;
   font-weight: 400;
   width: 220px;
   height: 70px;
   font-size: 14px;
   font-weight: 400;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #fff;
   padding: 20px 0 20px 70px;
   margin-bottom: 20px;

   span {
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
         opacity: 0.8;
      }
   }
   .arrowOpen {
      transition: all 0.2s;
      transform: scale(-1, 1);
      &:hover {
         transform: scale(-1, 1) translateX(-2px);
      }
   }
   svg {
      position: absolute;
      left: 25px;
      transition: all 0.2s;
      fill: white !important;
      cursor: pointer;
      &:hover {
         transform: translateX(-2px);
         opacity: 0.8;
      }
   }
}

ul {
   li {
      margin: 10px 0;
   }
}
.subMenu {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   position: relative;
   font-size: 14px;
   font-weight: 400;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #fff;
   width: 100%;
   height: 70px;
   height: auto;
   transition: all 0.2s;

   li {
      height: 30px;
   }

   .linkTitle {
      padding: 20px 0 20px 70px;
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      transition: all 0.2s;
      cursor: pointer;

      .menuItemIcon {
         position: absolute;
         left: 25px;
         transition: all 0.2s;
         fill: white;
      }

      .arowIcon {
         transition: all 0.15s ease-in;
         position: absolute;
         right: 50px;
         fill: white;
         transform: scale(0.9);
      }
      .arowIconOpen {
         fill: white;
         transition: all 0.15s ease-in;
         transform: scale(0.9) scaleY(-1);
      }

      &:hover {
         background-color: #ffd8be38;
      }
   }

   .subMenuOpen {
      height: 100px;
      width: 100%;
      padding: 10px 0 10px 70px;
      transition: all 0.3s;

      a {
         font-size: 13px;
         font-weight: 400;
         padding: 10px 0;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #ffffffba;
         width: 100%;
         transition: all 0.2s;
         &:hover {
            color: #fff;
            transition: all 0.2s;
         }
      }
   }

   .subMenuClosed {
      height: 0px;
      transition: all 0.3s;
      overflow: hidden;
      a {
         font-size: 13px;
         font-weight: 400;
         padding: 10px 0;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #fff;
         width: 100%;
         transition: all 0.2s;
         opacity: 0;
      }
   }
}

.active {
   font-weight: 700 !important;
   color: #fff !important;
}

svg {
   &:focus {
      outline: none;
   }
}

// Bottom logo
.disableFooterLogo {
   position: absolute;
   bottom: 20px;
   left: 20px;
   width: auto;
   height: auto;
   transition: all 0.2s ease-in;
   opacity: 0;
   img {
      width: 65%;
   }
}

.sidebarFooterLogo {
   opacity: 1;
   position: absolute;
   bottom: 20px;
   left: 20px;
   width: auto;
   height: auto;
   transition: all 0.2s ease-in;

   img {
      width: 65%;
   }
}

.servicesIcon g g path {
   stroke: white;
}

.usersIcon g g path {
   fill: white;
}
