.preloaderContainer {
   width: 100%;
   height: 100vh;
   z-index: 5000;
   background: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   top: 0;
}
