@import '../../../assets/styles/utils/variables';
.input {
   width: 100%;
   height: 35px;
   outline: none;
   padding: 0px 15px;
   transition: all 0.2s ease;
   border: solid 2px $color-02-grayscale-04-gray-bluish;
   box-shadow: none;
   border-radius: 6px;
   font-size: 12px;
   color: $color-01-brand-08-centili-dark-blue;
   font-weight: 400;

   &::placeholder {
      font-size: 12px;
      color: #c2c3c7;
      font-weight: normal;
   }
   &:disabled {
      background: $color-02-grayscale-04-gray-bluish-disabled-background;
      cursor: not-allowed;
      font-weight: 500;
      color: #c3c4c8;
      &:hover {
         border: solid 2px $color-02-grayscale-04-gray-bluish;
      }
   }
   &:hover {
      border: 2px solid $color-01-brand-03-centili-orange;
   }

   &:focus {
      outline: none;
      border: 2px solid $color-01-brand-01-centili-orange !important;
   }
}

.active {
   outline: none;
   border: 2px solid $color-01-brand-01-centili-orange !important;
}
