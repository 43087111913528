@import '../../assets/styles/utils/variables';
.paginationWrap {
   background: white;
   height: 80px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   border-radius: 0 0 12px 12px;

   h5 {
      font-size: 12px;
      font-weight: 500;
   }
   .pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      height: 100%;
      width: auto;
      & > * {
         width: auto;
         height: 100%;
      }
   }
   .previousLast,
   .previous,
   .next,
   .nextLast {
      fill: $color-01-brand-08-centili-dark-blue;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
         transition: all 0.2s;
         // background: #ffd8be;
         background: $color-01-brand-07-centili-blue;
         border-radius: 6px;
      }

      &:active {
         transition: all 0.2s;
         filter: contrast(0.8);
      }
   }

   .disabled {
      fill: $color-02-grayscale-04-gray-bluish;
      cursor: unset;
      pointer-events: none;
      &:hover {
         transition: all 0.2s;
         background: none;
         border-radius: 6px;
      }
   }

   .rowsSelect,
   .pageCount,
   .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      & > * + * {
         margin-left: 10px;
      }
   }
   .pageCount {
      min-width: 105px;
   }
}

.searching > * {
   pointer-events: none;
}
