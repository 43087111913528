@import '../../../assets//styles//utils/variables';

.button {
   width: 140px;
   height: 36px;
   padding: 0 10px;
   border: none;
   outline: none;
   cursor: pointer;
   font-size: 12px;
   font-weight: 600;
   border-radius: 6px;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   transition: all 0.2s ease-in;

   &:focus {
      transition: all 0.3s ease-in;
   }

   &:hover {
      transition: all 0.3s ease-in;
   }

   &:active {
      transition: all 0.1s;
      filter: contrast(0.8);
   }
   &:disabled {
      color: #b1b1b1;
      background-color: #e8e8e8;
      svg {
         filter: grayscale(1);
      }
      &:hover {
         box-shadow: none !important;
         transition: all 0.3s ease-in;
         transform: none !important;
         color: #b1b1b1 !important;
         background-color: #e8e8e8 !important;
         cursor: not-allowed;
      }
   }
}

.search {
   width: 100px;
   color: $color-01-brand-01-centili-orange;
   background-color: $color-01-brand-04-centili-orange;
   &:hover {
      transition: all 0.2s ease;
      background-color: $color-01-brand-04-centili-orange-hover;
      color: #dc5a03;
   }
}

.clear {
   font-weight: 600;
   border: solid 2px $color-02-grayscale-04-gray-bluish;
   color: $color-01-brand-01-centili-orange;
   color: $color-01-brand-08-centili-dark-blue;
   background-color: white;
   display: flex;
   flex-direction: row-reverse;
   justify-content: space-around;

   svg {
      transition: all 0.1s ease-in;
      transform-style: preserve-3d;
      transform: rotate(45deg);
   }

   &:hover {
      transition: all 0.2s ease;
      background-color: $color-01-brand-07-centili-blue;
      border: solid 2px $color-01-brand-07-centili-blue;
   }
}

.white {
   color: $color-01-brand-01-centili-orange;
   color: $color-01-brand-08-centili-dark-blue;
   font-weight: normal;
   background-color: white;
   display: flex;
   flex-direction: row-reverse;
   justify-content: space-around;

   svg {
      width: 15px;
      transition: all 0.1s ease-in;
      transform-style: preserve-3d;
   }
}

.delete {
   flex-direction: row-reverse;
   background-color: rgba(232, 63, 63, 0.2);
   color: rgba(232, 63, 63, 1);
}

.filter {
   font-weight: 600;
   border: none;
   height: 36px;
   color: #25316a;
   background-color: #ffffff00;
   width: 110px;
   font-size: 12px;
   display: flex;
   flex-direction: row-reverse;
   justify-content: space-between;

   svg {
      position: relative;
      left: 2px;
      transition: all 0.2s;
      fill: #0532f0;
      transform: scale(0.9);
   }

   &:hover {
      transition: all 0.2s ease;
   }
}

.filterOpen {
   border-radius: 4px;
   border: none;
   background: #0532f0;
   color: #fff;
   transition: all 0.2s linear !important;

   svg {
      transition: all 0.2s;
      fill: #fff;
   }
}

.arrowUp {
   transition: all 0.3s ease-in;
   border: 2px solid #ff9300;
   box-shadow: 0 4px 20px 0 rgba(37, 49, 106, 0.12);
   transform: translateX(1.5px);
   &:hover {
      transition: all 0.2s ease;
   }
   svg {
      top: -1px;
      transition: all 0.2s;
      transform: scale(0.9) scaleY(-1);
      fill: #ff6700;
   }
}

.primary {
   @extend .search;
   width: 140px;
}

.secondary {
   @extend .filter;
   width: 110px;
}

.blue {
   width: 140px !important;
   color: #0532f0;
   background-color: #cfddf1;
   &:hover {
      background-color: #bcd1ee;
   }
}

.template {
   @extend .blue;
   svg {
      width: 13px;
   }
}
