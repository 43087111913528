@import '../../../assets/styles/utils/variables';

.textarea {
   width: 100%;
   height: 70px;
   resize: none;
   transition: all 0.2s ease;
   border: solid 2px $color-02-grayscale-04-gray-bluish;
   transition: all 0.2s ease;
   font-size: 12px;
   border-radius: 6px;
   padding: 10px 5px 0 15px;
   color: $color-01-brand-08-centili-dark-blue;

   &:hover {
      border: 2px solid $color-01-brand-03-centili-orange;
   }
   &:focus {
      outline: none;
      border: 2px solid $color-01-brand-01-centili-orange !important;
   }
   &:disabled {
      background: $color-02-grayscale-04-gray-bluish-disabled-background;
      cursor: not-allowed;
      font-weight: 500;
      color: #c3c4c8;
      &:hover {
         border: solid 2px $color-02-grayscale-04-gray-bluish;
      }
   }
}
