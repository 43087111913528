.deleteModal {
   width: 500px !important;

   .modalWrap {
      padding: 50px 30px;
      p {
         font-size: 13px;
         .errorMessage {
            font-style: italic;
            font-weight: 500;
            color: #d85600;
         }
      }
   }

   .buttonWrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px 30px 20px 0px;
      & > * + * {
         margin-left: 20px;
      }
   }
}
