@import '../../../../assets/styles//utils/variables';

.buttonLink {
   width: 214px;
   height: 35px;
   padding: 0 10px;
   border: none;
   outline: none;
   cursor: pointer;
   font-size: 12px;
   font-weight: 600;
   border-radius: 4px;
   box-shadow: 0 4px 20px 0 rgba(37, 49, 106, 0.08);
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   transition: all 0.2s ease-in;
   color: $color-01-brand-01-centili-orange;
   background-color: $color-01-brand-04-centili-orange;

   &:focus {
      transition: all 0.3s ease-in;
   }

   &:hover {
      transition: all 0.2s ease;
      background-color: $color-01-brand-04-centili-orange-hover;
      color: #dc5a03;
   }
}
