.mainContainer {
   text-align: center;
   margin-top: 5%;
   .headlineText {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
   }
   .message {
      opacity: 0.7;
      font-size: 0.8rem;
   }

   .imageContainer {
      width: auto;

      img {
         width: auto;
      }
   }
}
