@import '../utils/variables';

.multi-select,
.singleInput,
.dropdownUp,
.paginationSelect {
   --rmsc-main: $color-01-brand-01-centili-orange;
   --rmsc-hover: $color-02-grayscale-04-gray-bluish-hover;
   --rmsc-border: $color-02-grayscale-04-gray-bluish;
   --rmsc-gray: $color-01-brand-08-centili-dark-blue;
   --rmsc-bg: #fff;
   --rmsc-p: 5px; /* Spacing */
   --rmsc-radius: 6px; /* Radius */
   --rmsc-h: 31.5px; /* Height */
   width: 130px;
   // margin: 0 auto;
   font-size: 12px;
   font-weight: 400;
   // height: 14px !important;
   // box-shadow: 0 4px 20px 0 rgba(37, 49, 106, 0.08);

   .dropdown-heading {
      padding: 0 5px 0 15px;
      border: none;
      cursor: pointer;
      height: 31.5px !important;
   }
   .dropdown-heading-value > span {
      // font-weight: 500;
      // font-size: 13px;
      color: $color-01-brand-08-centili-dark-blue !important;
      line-height: normal;
   }
   .dropdown-content {
      width: fit-content;
      font-size: 12px;
   }

   .select-panel {
      font-size: 12px;
   }
   .select-panel span {
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      letter-spacing: normal;
   }
   .select-panel label {
      transition: all 0.1s;
      border-radius: 6px;
      margin: 5px 0;
   }

   .select-panel > div {
      margin-bottom: 0;
      border-bottom: none;
   }
   .select-item {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0;
      width: 280px;

      &:hover {
         background: $color-02-grayscale-04-gray-bluish-hover !important;
      }

      span {
         padding-left: 10px;
      }
   }

   .select-item .selected {
      background: red !important;
   }
   .panel-content {
      border-radius: 8px !important;
      padding: 20px 20px;
      box-shadow: 0 10px 24px 8px rgba(0, 21, 108, 0.15) !important;
   }

   .search-clear-button {
      top: -10px;
      transform: scale(0.7);
      color: $color-01-brand-08-centili-dark-blue !important;
   }

   & svg {
      transform: scale(0.7);
      color: $color-01-brand-08-centili-dark-blue !important;
   }

   input[type='checkbox'] {
      display: none;
   }

   input[type='checkbox'] + *::before {
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      border-radius: 4px;
      border: solid 2px $color-02-grayscale-04-gray-bluish;
      background-color: #fff;
      text-align: left;
      margin-right: 10px;
      vertical-align: top;
   }

   input[type='checkbox']:active + *::before {
      transform: scale(0.93);
      transition: all 0.1s;
   }

   input[type='checkbox']:checked + *::before {
      content: '';
      display: inline-block;
      background: url(../../images/icons/checkmark-orange.png) no-repeat;
      background-position: center;
      background-size: 80%;
      color: $color-01-brand-01-centili-orange;
      text-align: center;
      width: 13px;
      height: 13px;
      border-radius: 4px;
      border: solid 2px $color-01-brand-01-centili-orange;
      background-color: #ffd8be;
      margin-right: 10px;
   }
   input[type='text'] {
      padding: 0px 30px;
      border-radius: 6px;
      border: solid 2px $color-02-grayscale-04-gray-bluish;
      margin-bottom: 10px;
      height: 35px;
      font-size: 12px;
   }

   ::placeholder {
      color: rgb(207, 207, 207);
      opacity: 1; /* Firefox */
      font-weight: 400;
   }

   & ::-webkit-scrollbar {
      width: 5px;
      height: 0;
   }

   & ::-webkit-scrollbar-track {
      background: #e7ecff91;
      outline: none;
      border-radius: 20px;
   }

   & ::-webkit-scrollbar-thumb {
      background: #cfddf1;
      border-radius: 30px;
   }
}

// Dropdown position for dropdownUp class
.dropdownUp {
   .dropdown-content {
      top: -1000% !important;
   }
}

.dropdown-container {
   transition: all 0.2s ease;
   border: 2px solid $color-02-grayscale-04-gray-bluish !important;
   border-radius: 6px !important;

   &:hover {
      border: 2px solid $color-01-brand-03-centili-orange;
   }
   &:focus-within {
      outline: none;
      border: 2px solid $color-01-brand-01-centili-orange !important;
      transition: all 0.2s ease;
      box-shadow: none !important;
      border-color: var(--rmsc-main);
   }
}

.singleInput {
   width: 130px;
}
.singleInput input[type='checkbox'] + *::before,
input[type='checkbox'] + *::after {
   display: none;
}

.singleInput input[type='checkbox']:checked + *::before {
   content: '';
   display: none;
   background: none;
   width: 0;
   height: 0px;
   border: none;
   background-color: none;
   margin-right: 10px;
}

.select-panel > div::before {
   content: url(../../images/icons/search.png);
   display: inline-block;
   position: absolute;
   top: 5px;
   left: 3px;
   transform: scale(0.55);
   filter: opacity(0.3);
}

.goodMessage {
   height: 8px;
   width: 8px;
   margin-left: 5px !important;
   border-radius: 50px;
   padding: 0 !important;
}

.good {
   background: #5cd653;
}
.forCheck {
   background: #f88c44;
}
.missing {
   background: #ff0000;
}

.disabled {
   cursor: not-allowed;

   .dropdown-container {
      background: $color-02-grayscale-04-gray-bluish-disabled-background !important;
      pointer-events: none;
      border: 2px solid $color-02-grayscale-04-gray-bluish !important;

      .dropdown-heading {
         cursor: not-allowed !important;
      }
   }

   span {
      color: #b7b7b7 !important;
   }
   svg {
      display: none;
   }
}

//Pagination rows select input
.paginationSelect {
   @extend .singleInput;
   width: 80px;
   box-shadow: none;
   border-radius: 6px;
   .dropdown-container {
      @extend .dropdown-container;
      // border: solid 1px #dfe3e9;
      border: 2px solid $color-02-grayscale-04-gray-bluish !important;
   }
   .dropdown-content {
      width: 120px;
      font-size: 12px;
   }
   .panel-content {
      padding: 15px 15px;
   }
   .dropdown-content {
      top: -950% !important;
      z-index: 1000;
   }
   .clear-selected-button {
      display: none;
   }
   span {
      font-weight: 500;
   }
}

//Pagination rows select input
.ycoin {
   .dropdown-content {
      top: -620% !important;
      z-index: 1000;
   }
}

.clear-selected-button {
   border: none;
   outline: #524f49;
   transform: scale(0.8);
   position: relative;
   top: 1px;
   left: 5px;
   svg {
      color: $color-01-brand-08-centili-dark-blue !important;
   }
   &:active {
      outline: none;
   }
}

.panel-content {
   border: none !important;
   box-shadow: 0 10px 24px 8px rgba(0, 21, 108, 0.15) !important;
}

.selected {
   background: $color-01-brand-07-centili-blue !important;
}

.singleInputStorefront {
   width: 100%;
}
