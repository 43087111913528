@import '../../../assets/styles/utils/variables';

.datePicker {
   flex: 0 0 280px !important;
   min-width: 280px;
   height: 35px;
   border-radius: 6px;
   border: solid 2px $color-02-grayscale-04-gray-bluish;

   & > div {
      height: 100%;
      font-size: 12px !important;
      display: flex;
      margin: auto 0;
      padding-top: 9px !important;
   }

   span {
      top: 6px !important;
   }
   svg {
      top: 5px !important;
   }

   div,
   input {
      border: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;

      &:focus,
      &:hover {
         border: none !important;
      }
   }

   input + span {
      font-family: 'Poppins', sans-serif;
      color: $color-01-brand-08-centili-dark-blue !important;
   }

   span[aria-placeholder] {
      color: #c2c3c7 !important;
   }

   &:hover {
      border: 2px solid $color-01-brand-03-centili-orange !important;
   }

   &:focus,
   &:focus-within {
      border: 2px solid $color-01-brand-01-centili-orange !important;
   }
}
