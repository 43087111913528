// Header
.modalHeader {
   width: 100%;
   background: #e7ecff;
   border-radius: 8px 8px 0 0;
   height: 60px;
   padding: 0px 30px;
   display: flex;
   align-items: center;
   h2 {
      font-size: 14px;
   }
}

.closeIcon {
   transform: scale(0.9);
   position: absolute;
   right: 20px;
   top: 15px;
   transform-style: preserve-3d;
   transition: all 0.2s;
   cursor: pointer;
   &:hover {
      filter: contrast(0.7);
   }
}
