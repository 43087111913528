.checkboxSpan {
   display: flex;
   flex-direction: row;
   align-items: center;
   font-size: 12px;
   font-weight: 400;
   font-stretch: normal;
   letter-spacing: normal;
   cursor: pointer;
   width: max-content;
   padding: 5px 10px;
}

input[type='checkbox'] {
   display: none;
}

input[type='checkbox'] + *::before {
   content: '';
   display: inline-block;
   width: 13px;
   height: 13px;
   border-radius: 4px;
   border: solid 2px #c2c3c7;
   background-color: #f4f2f2;
   text-align: left;
   margin-right: 5px;
   vertical-align: top;
}

input[type='checkbox']:active + *::before {
   transform: scale(0.93);
}

input[type='checkbox']:checked + *::before {
   content: '';
   display: inline-block;
   background: url(../../../assets/images/icons/checkmark-orange.png) no-repeat;
   background-position: 40% 40%;
   background-size: 85%;
   color: #ff6600;
   text-align: center;
   width: 13px;
   height: 13px;
   border-radius: 4px;
   border: solid 2px #ff6600;
   background-color: #ffd8be;
   margin-right: 5px;
}

::placeholder {
   color: rgb(207, 207, 207);
   opacity: 1; /* Firefox */
}

// Disabled
.disabled {
   pointer-events: none;
   filter: opacity(0.7);

   &::before {
      pointer-events: none;
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      border-radius: 4px;
      border: solid 2px #c2c3c7;
      background-color: #b9b9b9 !important;
      filter: grayscale(1);
      text-align: left;
      margin-right: 5px;
      vertical-align: top;
   }
}
