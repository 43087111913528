@import '../utils/variables';

.react-tabs__tab-list {
   border-bottom: 2px solid $color-02-grayscale-04-gray-bluish;
   margin: 0 0 10px;
   padding: 0;
}

.react-tabs__tab {
   margin: 0;
   padding: 15px 35px;
   color: #25316a61;
   border: none;
   position: relative;
   top: 2px;
   span {
      font-size: 13px;
      font-weight: 500;
   }

   svg {
      fill: $color-01-brand-01-centili-orange;
      transition: all 0.2s;
      transform-style: preserve-3d;
      transform: scale(0.85) rotate(45deg);
      position: absolute;
      right: 15px;
      opacity: 0;
   }

   &:hover {
      transition: 0.2s;
      transition: 0.2s;
      // background: #faebd773;
      color: $color-01-brand-01-centili-orange;
      border-radius: 4px 4px 0 0;
      svg {
         opacity: 1;
      }
   }
}

.react-tabs__tab:focus {
   box-shadow: none;
   border-color: none !important;
   outline: none;
   border: none;
   box-shadow: inset 0 -2px 0 $color-01-brand-01-centili-orange;
}

.react-tabs__tab:focus:after {
   content: '';
   position: absolute;
   height: 0;
   left: -4px;
   right: -4px;
   bottom: -5px;
   border: none;
}
.react-tabs__tab--selected {
   background: none;
   border-color: none;
   border: none;
   border: none;
   box-shadow: inset 0 -2px 0 $color-01-brand-01-centili-orange;
   color: $color-01-brand-01-centili-orange;
   border-radius: 5px 5px 0 0;
   position: relative;
   top: 2px;
   span {
      font-size: 13px;
      font-weight: 500;
   }
   svg {
      fill: $color-01-brand-01-centili-orange;
      transition: all 0.2s;
      transform-style: preserve-3d;
      transform: scale(0.85) rotate(45deg);
      position: absolute;
      right: 15px;
      opacity: 0;
   }
   &:hover {
      transition: 0.2s;
      transition: 0.2s;
      color: $color-01-brand-01-centili-orange;
      border-radius: 4px 4px 0 0;
      svg {
         opacity: 1;
      }
   }
}

// TABS
.react-tabs__tab-panel--selected {
   position: relative;

   &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
   }

   &::-webkit-scrollbar-track {
      background: #e8e8e8;
      outline: none;
      border-radius: 20px;
   }

   &::-webkit-scrollbar-thumb {
      background: linear-gradient(-110deg, #ff8a00, #ff2f00c4);
      border-radius: 10px;
   }
}
