.buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;
    & > * + * {
        margin-left: 20px;
     }
}
.modalWrap {
    padding: 30px 30px;
    height: 60% !important;
    label {
       font-size: 12px;
       color: #25316a;
       opacity: 0.9;
       font-weight: 600;
    }

    .contentWrapper {
        display: flex;
        .leftContainer {
           width: 50%;
           padding: 15px;
        }
        .rightContainer {
           width: 50%;
           padding: 15px;
        }
        .starColor {
           color: ff6600;
        }
     }
 
    .mandatoryFieldsLabel {
       font-size: 12px;
       opacity: 0.7;
       margin-bottom: 15px;
    }
 
    .mandatoryStar {
       color: red;
    }
 
    .inputWrap {
       margin-bottom: 12px;
    }
 }

 .buttonWrap {
   display: flex;
   margin-top: 40px;
   align-items: center;
   justify-content: flex-end;
   & > * + * {
      margin-left: 20px;
   }
}

.errorText {
   display: block;
   font-size: 10px;
   color: red;
   font-weight: 600;
}