@import '../../../assets/styles/utils/variables';

.titleContainer {
   margin: 30px 0px;
   color: #0432f0;
   font-weight: 600;

   h2 {
      color: #0432f0;
      font-weight: 600;
   }
}

.title {
   color: #0432f0;
   font-weight: 600;
   font-size: 1.2rem;
   margin-top: 20px;
}

.filtersMenu {
   width: 100%;
   display: flex;
   margin-bottom: 10px;

   .filtersLeft {
      width: 65%;
      display: flex;
      justify-content: flex-start;
      column-gap: 10px;
      align-items: center;

      .filtersInput {
         width: 150px;
      }
   }

   .filtersRight {
      width: 35%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 10px;

      .filtersButtons {
         width: 100px;
      }

      .filtersRight {
         flex: 1;
         display: flex;
         justify-content: flex-end;
      }

      .filterButtons {
         width: 100px;
         margin: auto 0px;
         margin-left: 15px;
      }
   }
}
.inputContainer {
   width: fit-content;
   font-size: 14px;
   display: flex;
   column-gap: 10px;
   justify-content: flex-end;
   flex-wrap: wrap;
   row-gap: 5px;

   .inputWrap {
      margin-bottom: 0px;
      width: 240px;

      .rateLabel {
         font-size: 12px;
      }
   }

   .buttonWrap {
      margin-top: 0 !important;
      column-gap: 0px;
      align-items: center;

      button {
         padding: 0 15px;
         width: 100px;
         margin-left: 5px;
      }
   }

   .editIcon {
      width: 20px;
      height: 20px;
      cursor: pointer;
   }

   .rateLabel {
      margin-top: 5px;
      color: #25336b;

      span {
         font-weight: 600;
         .rate {
            color: #ff6700;
         }
      }
   }
}

.servicesTable {
   margin-top: 40px;

   tbody {
      td {
         padding: 10px 10px 10px 50px;

         &:nth-last-of-type(-n + 2) {
            width: 15%;
            padding: 10px 10px 10px 50px !important;
         }
      }
   }

   .status {
      span {
         width: 100px !important;
         height: 15px !important;
         text-align: center;
         position: relative !important;
         left: 0px !important;
         border-radius: 4px !important;
         padding-top: 1px;
      }
   }

   .activated {
      span {
         background-color: #a3eec6 !important;
      }
   }

   .notActivated {
      span {
         background-color: #97c0f0 !important;
      }
   }

   .iconsContainer {
      display: flex;
      column-gap: 10px;
      justify-content: flex-end;
      padding-right: 35px;

      svg {
         cursor: pointer;
      }

      .editIcon {
         fill: #25316a;
      }
      .eyeIcon {
         stroke: #25316a;
         margin-top: 5px;
      }
   }
}

.details {
   width: 100%;
   margin: 60px 0px;

   .keys,
   .values {
      font-size: 15px;
      width: 90%;
      margin: 0 auto;
      justify-content: space-between;
      display: flex;

      div {
         flex: 1;

         &:first-child {
            flex: 1.6;
         }
      }
   }

   .keys div {
      font-weight: bold;
      margin-bottom: 10px;
   }

   .values {
      .activated {
         color: #5cd653;
      }

      .notActivated {
         color: #97c0f0;
      }
   }
}

.filterRowEdit {
   display: flex;
   a {
      margin: auto 0px;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
   }
}

.serviceDetailsTable {
   margin-top: 30px;

   tr {
      display: flex;
      padding-left: 50px;
      th {
         width: 12% !important;
         padding: 20px 0px 20px 20px !important;
      }

      td {
         div {
            display: flex;
            .tableCellLabel {
               margin: auto;
               margin-left: 5px;
            }
         }
         width: 12% !important;
         padding: 10px 0px 10px 20px !important;
         margin: auto 0px;
      }
   }
}

.iconsContainer {
   display: flex;
   font-weight: 600;
   justify-content: flex-end;
   padding-right: 30px;

   svg {
      width: 20px;
      margin: 10px;
      cursor: pointer;
   }

   .categoryIcon {
      position: relative;
      top: 3px;
   }

   .customDeleteIcon {
      fill: #e83f3f;
   }

   img {
      width: 18px !important;
   }

   .seeMore {
      transform: rotate(90deg);
      margin-top: 22px;
   }
   label {
      cursor: pointer;
   }
}

.status {
   span {
      width: 100px !important;
      height: 15px !important;
      text-align: center;
      position: relative !important;
      left: 0px !important;
      border-radius: 4px !important;
      padding-top: 1px;
   }
}

.activated {
   span {
      background-color: #a3eec6 !important;
   }
}

.notActivated {
   span {
      background-color: #97c0f0 !important;
   }
}

.inputWrap {
   margin-bottom: 12px;

   label {
      font-size: 10px;
      color: #ff6700;
      font-weight: 600;
   }
}

.filterInputs {
   min-width: 300px;
   width: auto;
}

.errorText {
   display: block;
   font-size: 10px;
   color: red;
   font-weight: 600;
}

.buttonWrap {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   margin-top: 30px;
   & > * + * {
      margin-left: 20px;
   }
}

.messageWrap {
   margin: 40px 0;
   background-color: white;
   padding: 20px 10px 20px 10px;
   box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
   border-radius: 12px;
   width: 100%;
   font-size: 12px;
}

@media screen and (max-width: 1300px) {
   .filtersMenu {
      flex-direction: column;
   }
}
