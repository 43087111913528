.filters {
   display: flex;
   .filtersLeft {
      display: flex;
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 5px;
      align-content: flex-start;
      align-items: flex-end;

      & > div {
         flex: 0 0 130px;
      }
   }

   .filtersRight {
      width: 20%;
      display: flex;
      column-gap: 5px;
      justify-content: flex-end;
      align-items: flex-end;

      button {
         flex: 0 0 100px;
      }
   }
}

.filtersBottom {
   display: flex;
   margin-top: 5px;
   column-gap: 5px;
   flex-wrap: wrap;
   row-gap: 5px;

   & > div {
      flex: 0 0 130px;
   }

   animation: openFilter 0.3s;

   @keyframes openFilter {
      from {
         height: 0;
         opacity: 0;
      }
      to {
         height: 40px;
         opacity: 1;
      }
   }
}

.filterBottomHidden {
   display: flex;
   margin-top: 5px;
   column-gap: 10px;
   align-items: center;
   justify-content: flex-start;
   // gap: 20px;
   opacity: 1;
   height: 0;
   overflow: hidden;
   transition: all 0.2s ease-in;
   animation: hideFilter 0.5s;
   opacity: 0;
   & > div {
      flex: 0 0 180px;
   }

   @keyframes hideFilter {
      from {
         height: 40px;
      }
      to {
         height: 0px;
      }
   }
}
.filterBottomHidden > * {
   transition: all 0.2s ease-in;
   opacity: 0;
}

.filtersDate {
   label {
      font-size: 10px;
      color: #0532f0;
      font-weight: 600;
   }
}

.lowOpacityTimeDate {
   font-size: 9pt;
   padding-top: 5px;
   font-weight: 600;
}

.messageWrap {
   margin: 30px 0;
   background-color: white;
   padding: 20px 10px 20px 10px;
   box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
   border-radius: 12px;
   width: 100%;
   font-size: 12px;
}

.customDatepicker {
   width: 165px;
   height: 35px;
   outline: none;
   padding: 0px 15px;
   transition: all 0.2s ease;
   border: solid 2px #dcdee7;
   box-shadow: none;
   border-radius: 6px;
   font-size: 12px;
   color: #25316a;
   font-weight: 400;
}

.dateWrapper {
   position: relative;
}

.flagCell {
   display: flex;

   img {
      margin-top: -2px;
   }
}

.chartWrapper {
   margin-top: 25px;
   padding: 20px 10px 20px 10px;
   box-shadow: 0 8px 25px 0 #244bf026;
   border-radius: 12px;
}

.tablesWrapper {
   box-shadow: 0 8px 25px 0 #244bf026;
   border-radius: 20px;

   .totalsTable {
      margin-top: -30px !important; /* tableWrap from Table component inserts 40px margin
  which can't be controlled by prop, and Firefox doesn't support :has() selector */
      margin-bottom: 10px !important;

      thead {
         border-bottom: none !important;
         th {
            font-size: 13px !important;
            font-weight: normal !important;
            padding: 25px 50px 0 10px !important;
            text-align: right !important;
         }
      }

      tr {
         border-bottom: none !important;
         &:hover {
            background: inherit !important;
         }

         td {
            font-size: 20px !important;
            font-weight: bold !important;
            text-align: right !important;
            padding: 0 50px 25px 10px !important;
         }
      }
   }

   .revenueDataTable {
      td {
         font-size: 16px !important;
      }
      th {
         font-size: 16px !important;
      }
   }
}

.chartTitle {
   margin-top: 30px;
   font-size: 22px;
   font-weight: 600;
}

.chartLegendContainer {
   margin-top: 20px;
   text-align: center;
   span {
      font-size: 15px;
      font-weight: 600;
      padding: 10px 20px;

      .chartLegendCircle {
         display: inline-block;
         width: 0.9em;
         height: 0.9em;
         border-radius: 50%;
         margin-right: 5px;
         vertical-align: middle;
      }
   }
}

.exportContainer {
   width: fit-content;
   display: flex;
   column-gap: 20px;
   padding: 15px;
}

div[class*='paginationWrap'] + .exportContainer {
   position: relative;
   top: -60px;
   left: 20px;
   padding: 0;
}

.exportWarningContainer {
   display: flex;
   align-items: center;
   column-gap: 10px;
}

.exportWarningMessage p {
   font-size: 12px;
   color: #ff6600;
}

.exportWarningMessage p:nth-child(2) {
   font-weight: 600;
}
