.ps__thumb-x {
   background-color: #cfddf1;
}

// .ps__rail-x:hover > .ps__thumb-x {
//    background-color: #cfddf1;
// }

.ps .ps__rail-x {
   &:hover {
      background-color: #e7ecff91;
      opacity: 0.9;
      border-radius: 10px;
      .ps__thumb-x {
         background-color: #cfddf1;
      }
   }
}

.ps__rail-x.ps--clicking {
   background-color: #e7ecff91 !important;
   .ps__thumb-x {
      background-color: #c3d5ee;
      height: 11px;
   }
}
