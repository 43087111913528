.tableLoader {
   width: 90%;
   height: 600px;
   position: relative;
   display: flex;
   align-items: baseline;
   justify-content: center;
   margin: 0 auto;
   padding-top: 50px;

   svg {
      transform: scale(1);
      position: absolute;
   }
}
