.avatar {
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;

   img {
      width: auto;
      height: auto;
      transform: scale(0.9);
   }

   svg {
      transition: all 0.15s ease-in;
      transform: scale(0.9);
      position: relative;
      left: inherit;
      right: 2px;
      top: 3px;
      fill: #25316a;
   }
}

.arrowUp svg {
   transition: all 0.2s ease-in;
   transform: scale(0.9) scaleY(-1);
   fill: #ff6700;
}
