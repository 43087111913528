.rowContainer {
    display: flex;
    margin-bottom: 15px;
 }
 .key {
    width: 50%;
    font-weight: bold;
    font-size: 0.9rem;
    padding: 3px;
 }
 .value {
    width: 50%;
    word-break: break-all;
    font-size: 0.8rem;
    position: relative;
    top: 2px;
    padding: 3px;
 }

 img {
    width: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;    
 }