@import 'base/reset';
@import 'base/base';
@import 'base/fonts';
@import 'utils/variables';
@import 'utils/typography';
@import 'utils/mixins';
@import 'pages/errorPages';
@import 'components/autoSuggest/theme';
@import 'components/multiselect';
@import 'components/tooltip';
@import 'components/toast';
@import 'components/tabs';
@import 'components/scrollbar';

//disable checkbox and svg
.pagination input[type='checkbox'],
input[type='radio'] {
   display: none;
}

.pagination svg {
   transform: scale(0.75);
}

//react modal pagination
.ReactModal__Overlay {
   opacity: 0;
   transition: opacity 0.1s ease-in-out;
}

.ReactModal__Overlay--after-open {
   opacity: 1;
   transition: opacity 0.15s ease-in-out;
}

.ReactModal__Overlay--before-close {
   opacity: 0;
   transition: opacity 0.15s ease-in-out;
}

.modal {
   background-color: white;
   width: 600px;
   height: auto;
   max-height: 680px;
   box-shadow: 0 10px 24px 8px rgba(0, 21, 108, 0.15);
   border: none;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   margin: 0 auto;
   border-radius: 10px;
   outline: none;
   overflow: auto;
}

// tippy
.tippy-box {
   background-color: #25316a !important;
   color: #fff;
   border-radius: 6px !important;
   font-size: 14px;
   line-height: 1.4;
   outline: 0;
   transition-property: transform, visibility, opacity;
}

.export-csv-btn {
   position: relative;
   top: 25px;
   left: 0px;
}

a[download] {
   display: inline-block;
   width: fit-content;
}

div[class*='paginationWrap'] + a .export-csv-btn {
   position: relative;
   top: -60px;
   left: 25px;
}
