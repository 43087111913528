.counter {
   position: absolute;
   z-index: 10;
   width: 17px;
   height: 17px;
   background: #f58a42;
   color: white;
   font-weight: 600;
   font-size: 10px;
   text-align: center;
   vertical-align: middle;
   line-height: 18px;
   border-radius: 50px;
   top: -10px;
   right: -5px;
}
