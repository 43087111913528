/** Colors */
$grayscale-01-dark: #000000;
$color-02-grayscale-04-gray-bluish: #dcdee7;

$color-03-states-01-success: #5cd653;
$grayscale-03-gray: #c2c3c7;
$color-01-brand-05-centili-blue: #0532f0;
$pale-red: #e83f3f;
$color-01-brand-06-centili-blue: #3b7cff;
$grayscale-05-light: #ffffff;
$grayscale-02-dark-gray: #5c6069;
$grayscale-04-light-gray: #f4f2f2;
$grayscale-04-light-gray-placeholder: #c2c3c7;
$color-01-brand-07-centili-blue: #cfddf1;
$color-01-brand-08-centili-dark-blue: #25316a;
$color-01-brand-09-dark-blue: rgba(12, 38, 109, 0.7);
$color-01-brand-01-centili-orange: #ff6600;
$color-01-brand-04-centili-orange: #ffd8be;
$color-01-brand-04-centili-orange-hover: #fcccac;
$color-01-brand-02-centili-orange: #ff8b40;
$color-01-brand-03-centili-orange: #ffb180;
$color-03-states-02-success: rgba(92, 214, 83, 0.5);
$color-03-states-03-success: rgba(92, 214, 83, 0.15);
$color-03-states-04-destructive: #ff5734;
$color-03-states-05-destructive: rgba(255, 87, 52, 0.65);
$color-03-states-06-destructive: rgba(255, 87, 52, 0.15);

$color-01-brand-04-centili-orange-3-2: rgba(255, 216, 190, 0.3);
$color-01-brand-10-centili-light-bl-2: #f0f3fe;
$color-02-grayscale-05-light-gray: #f8f8f8;
$color-01-brand-05-centili-orange: #ffe4d2;
$color-01-brand-10-centili-light-blue: #ebf1ff;

//States
$color-02-grayscale-04-gray-bluish-hover: #e7ecff;
$color-02-grayscale-04-gray-bluish: #dcdee7;
$color-02-grayscale-04-gray-bluish-disabled: #dcdee7;
$color-02-grayscale-04-gray-bluish-disabled-background: #dcdee76b;

/** Typography */
$font-family: 'Poppins', sans-serif;
