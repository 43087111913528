@import '../../../assets/styles/utils/variables';

.wrap {
   height: 100%;
   padding: 0 40px 0 0;
}
.wrap > div {
   height: 100%;
}
.topMenu {
   height: 100%;
   width: 320px;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   border-left: 2px solid #dddfe5;
   padding-left: 60px;

   .topMenuText {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-left: 40px;
      margin-right: 20px;
      h3 {
         font-size: 16px;
         font-weight: 400;
         padding: 7px 0;
      }
      h5 {
         font-size: 11px;
         font-weight: 600;
         letter-spacing: -0.2px;
         width: max-content;
      }
   }
}
.dropdown {
   background: white;
   position: absolute;
   top: 90px;
   right: 30px;
   height: 95px;
   width: 165px;
   padding: 10px 15px;
   box-shadow: 0 10px 24px 8px rgba(0, 21, 108, 0.15);
   display: flex;
   flex-direction: column;
   border-radius: 8px;
   a {
      color: $color-01-brand-08-centili-dark-blue;
      cursor: pointer;
      padding: 10px;
      font-size: 13px;
      font-weight: 400;
      border-radius: 6px;
      display: flex;
      align-items: center;
      transition: all 0.1s;

      img {
         margin-right: 10px;
      }
      span {
         color: $color-01-brand-01-centili-orange;
         padding: 0px 15px 0 20px;
      }
      &:hover {
         background-color: $color-02-grayscale-04-gray-bluish-hover;
      }
   }
   &:focus {
      border: none;
      outline: none;
   }
   //    border: 2px solid red;
}

.active {
   transition: all 0.1s ease-in;
   // background: #ffd8be75;
   // opacity: 0.85;
}
