.footer {
   height: 50px;
   width: 100%;
   padding: 0px 40px 0 120px;
   background-color: rgba(215, 228, 246, 0.8);
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 12px;
   font-weight: 500;
   position: absolute;
   bottom: 0;
   color: #25316a;
   .footerLeft {
      h4 {
         font-weight: 500;
         font-size: 14px;
      }
   }
   .footerRight {
      h4 {
         font-size: 12px;
         font-weight: normal;
      }
   }
}
