// TABLE
.tableWrap {
   width: 100%;
   height: auto;
   background: white;
   border-radius: 12px 12px 0 0;
   margin-top: 40px;
   // overflow: auto;
   // box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
}

.mainTable {
   width: 100%;
   border: 1px solid red;
   border-collapse: collapse;
   border: none;
   table-layout: auto;

   th {
      text-align: left;
   }

   thead {
      border-bottom: 2px solid #dddfe5;
      th {
         padding: 20px 0px 20px 50px;
         font-size: 12px;
         font-weight: 600;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         svg {
            vertical-align: sub;
            margin-left: 10px;
         }
      }
   }

   tbody {
      tr {
         border-bottom: 2px solid #dddfe5;
         &:hover {
            transition: all 0.1s;
            background: #f1f5fb;
         }
      }
      td {
         position: relative;
         padding: 20px 10px 20px 50px;
         font-size: 12px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         word-break: break-word;

         span {
            width: 6.5px;
            height: 6.5px;
            border-radius: 1000px;
            display: inline-block;

            img {
               border-radius: 50%;
               width: 22px;
            }
         }
      }
   }
}
