.modalWrap {
   padding: 30px 40px;
   height: 60% !important;
   font-size: 16px;
   label {
      color: #25316a;
      opacity: 0.9;
      font-weight: 600;
   }

   .buttonWrap {
      display: flex;
      margin-top: 30px;
      align-items: center;
      justify-content: flex-end;
      & > * + * {
         margin-left: 20px;
      }
   }

   .textAreaWrap {
      margin-top: 20px;
   }
}
