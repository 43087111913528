// @include '../utils/_variables.scss';
@import 'utils/variables';
.Toastify__toast {
   border-radius: 8px;
   font-size: 12px;
   text-align: center;
   box-shadow: 0 4px 20px 0px #11044633;
}
.Toastify__progress-bar--dark {
   background: $color-01-brand-01-centili-orange;
   height: 3px;
   border-radius: 80px;
}

// Default toast
.Toastify__toast--dark {
   background: $color-01-brand-08-centili-dark-blue;
   background: #152158;
   color: #fff;
}
