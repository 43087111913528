.tippy-content {
   font-size: 11px;
   padding: 15px 25px;
   & > div {
      font-weight: 300;
   }
}

.tippy-arrow {
   width: 16px;
   height: 16px;
   color: $color-01-brand-08-centili-dark-blue;
}
