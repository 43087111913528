.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.container {
   display: flex;
   flex-wrap: wrap;
   row-gap: 2rem;
   column-gap: 2rem;
   align-items: center;
   margin-top: 30px;
   background: white;
   padding: 50px;
   border-radius: 18px;
}
.container span {
   color: black !important;
   font-size: 11px;
}
.container input {
   flex: 1;
   padding: 10px;
   margin: 5px;
   border: 1px solid #ccc;
   border-radius: 5px;
}

.container p {
   flex: 1;
   padding: 10px;
   margin: 5px;
   background-color: #f0f0f0;
   border: 1px solid #ccc;
   border-radius: 5px;
}

.buttonWrap {
   display: flex;
   margin-top: 30px;
   align-items: center;
   justify-content: flex-end;
   & > * + * {
      margin-left: 20px;
   }
}

.bundleTransactionTable {
   background-color: #f8f8f8;
}

.bundleHeader {
   td {
      font-weight: 600 !important;
   }
}

.editIcon {
   cursor: pointer;
}

.filterRowEdit {
   display: flex;
   margin-bottom: 20px;
   a {
      margin: auto 0px;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
   }
}

.filters {
   display: flex;
   .filtersLeft {
      display: flex;
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 5px;
      align-content: flex-start;
      align-items: flex-end;

      & > div {
         flex: 0 0 170px;
      }

      div[class*='multi-select'] {
         width: 170px;
      }
   }

   .filtersRight {
      width: 20%;
      display: flex;
      column-gap: 5px;
      justify-content: flex-end;
      align-items: flex-end;

      button {
         flex: 0 0 100px;
      }
   }

   .filtersDate {
      label {
         font-size: 10px;
         color: #0532f0;
         font-weight: 600;
      }
   }
}

.filterInputs {
   width: 250px !important;
}

.messageWrap {
   margin: 30px 0;
   background-color: white;
   padding: 20px 10px 20px 10px;
   box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
   border-radius: 12px;
   width: 100%;
   font-size: 12px;
}

.tablesHeader {
   display: flex;
   column-gap: 1rem;
   border-bottom: 3px solid #dcdee7;
   margin: 30px 0;
   div {
      font-weight: 600;
      height: 100%;
      padding-bottom: 1.5rem;
      cursor: pointer;
      font-size: 1rem;
   }

   div.active {
      color: #0532f0;
      border-bottom: 3px solid #0532f0;
      margin-bottom: -3px;
   }
}

.transactionsTable {
   thead th,
   tbody td {
      &:last-child {
         color: #b8bcce;
      }
   }
}

table {
   thead th,
   tbody td {
      padding: 20px 10px 20px 30px !important;
      &:nth-child(9),
      &:nth-child(10) {
         text-align: center;
      }
   }
   .status {
      span {
         width: fit-content !important;
         padding: 4px 10px;
         height: 20px !important;
         text-align: center;
         position: relative !important;
         left: 0px !important;
         border-radius: 4px !important;
      }
   }

   .canceled {
      span {
         background-color: #e1e7f8;
      }
   }

   .failed {
      span {
         background-color: rgba(#e83f3f, 0.3);
         color: #e83f3f;
      }
   }

   .success {
      span {
         background-color: rgba(#07cc03, 0.3);
         color: #07cc03;
      }
   }

   .pending {
      span {
         background-color: rgba(#ff6600, 0.2);
         color: #ff6600;
      }
   }
   .delivered {
      span {
         background-color: rgba(#2cba00, 0.2);
         color: #25336b;
      }
   }
   .excluded {
      span {
         background-color: #e1e7f8;
         color: #25336b;
      }
   }
   .lowOpacityTimeDate {
      font-size: 9pt;
      padding-top: 5px;
      font-weight: 600;
   }
}

.arrowIcon path {
   fill: #0532f0 !important;
}

.arrowIcon {
   width: 8px;
   cursor: pointer;
   transform: rotate(90deg);
   transition: all 0.3s;
}

td.active .arrowIcon {
   transform: rotate(90deg) rotateY(180deg);
   transition: all 0.3s;
}

td.active {
   border-left: 3px solid #0532f0;
}

.failedMsg {
   background-color: rgba(#e83f3f, 0.3);
   color: #e83f3f;
}

.successMsg {
   background-color: rgba(44, 186, 0, 0.2);
   color: #25336b;
}

@media (max-width: 768px) {
   .container {
      flex-direction: column;
   }
}
