// html,
// body,
@import '../utils/variables';

#root {
   height: 100%;
   overflow-x: hidden;
}

html {
   overflow-y: overlay;
   scrollbar-color: blue;
   color: $color-01-brand-08-centili-dark-blue;

   p,
   input,
   a {
      color: $color-01-brand-08-centili-dark-blue;
   }
}
// Chrome
html::-webkit-scrollbar {
   width: 6px;
   height: 7px;
}
html::-webkit-scrollbar-thumb {
   background-color: #ff6600;
   border-radius: 30px;
   background: linear-gradient(-110deg, #ff8a00, #ff2f00c4);
   border-radius: 10px;
}
html::-webkit-scrollbar-track {
   background: #f8fafd;
   outline: none;
   border-radius: 20px;
}

html {
   overflow-y: scroll;
   scrollbar-width: thin;
   // scrollbar-color: #ff2f00 #c2d2e4;
}
