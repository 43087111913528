@import '../../utils/variables';

.react-autosuggest__container {
   position: relative;
   height: auto;
   box-sizing: border-box;
   &:focus {
      border: none;
      outline: none;
   }
}

.react-autosuggest__input {
   width: 140px;
   height: 35px;
   padding: 0px 15px;
   font-size: 12px;
   font-weight: 400;
   border: 2px solid $color-02-grayscale-04-gray-bluish;
   transition: all 0.2s ease;
   border-radius: 6px;
   list-style-type: none;
   color: $color-01-brand-08-centili-dark-blue;
   transition: all 0.2s ease;
   &:hover {
      border: 2px solid $color-01-brand-03-centili-orange;
   }
   &:focus {
      outline: none;
      border: 2px solid $color-01-brand-01-centili-orange !important;
   }
   &:active {
      outline: none;
      border: 2px solid $color-01-brand-01-centili-orange !important;
   }
   &::placeholder {
      font-size: 12px;
      color: $grayscale-04-light-gray-placeholder !important;
      font-weight: normal;
   }
   &:disabled {
      background: $color-02-grayscale-04-gray-bluish-disabled-background !important;
      // border: solid 1px #dfe3e9;
      border: 2px solid $color-02-grayscale-04-gray-bluish;
      cursor: not-allowed;
      color: #c3c4c8;

      &:active {
         outline: none;
         border: none !important;
      }
      &::placeholder {
         color: #b7b7b7 !important;
      }
   }
}

.react-autosuggest__input::placeholder {
   color: black;
   font-weight: 400;
}

.react-autosuggest__container--open .react-autosuggest__input {
   list-style-type: none;
}

.react-autosuggest__suggestions-container {
   list-style-type: none;
   display: none;
}
.react-autosuggest__suggestions-container--open {
   display: block;
   position: absolute;
   top: 51px;
   width: 450px;
   box-shadow: 0 10px 24px 8px rgba(0, 21, 108, 0.15);
   font-weight: 400;
   font-size: 12px;
   max-height: 300px;
   overflow-y: auto;
   z-index: 50;
   // padding: 0 15px;
   overflow-x: hidden;
   background: #fff;
   border-radius: 8px;
   padding: 20px 20px;
}

.react-autosuggest__suggestion {
   padding: 12px 10px;
   cursor: pointer;
   margin: 2px 0px;
   border-radius: 6px;
}
.react-autosuggest__suggestion:hover {
   background-color: $color-02-grayscale-04-gray-bluish-hover;
}

ul {
   list-style-type: none;
}

//Scrollbar
.react-autosuggest__container ::-webkit-scrollbar {
   width: 7px;
   height: 4px;
}

.react-autosuggest__container ::-webkit-scrollbar-track {
   background: #fff;
   outline: none;
   border-radius: 20px;
}

.react-autosuggest__container ::-webkit-scrollbar-thumb {
   background-color: #e2e2e2;
   border-radius: 30px;
}

.react-autosuggest__suggestion--highlighted {
   background-color: $color-02-grayscale-04-gray-bluish-hover;
}
