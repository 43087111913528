.filters {
   display: flex;
   .filtersLeft {
      display: flex;
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 5px;
      align-content: flex-start;
      align-items: flex-end;

      & > div {
         flex: 0 0 130px;
      }
   }

   .filtersRight {
      width: 20%;
      display: flex;
      column-gap: 5px;
      justify-content: flex-end;
      align-items: flex-end;

      button {
         flex: 0 0 100px;
      }
   }
}

.filtersBottom {
   display: flex;
   margin-top: 5px;
   column-gap: 5px;
   flex-wrap: wrap;
   row-gap: 5px;

   & > div {
      flex: 0 0 130px;
   }

   animation: openFilter 0.3s;

   @keyframes openFilter {
      from {
         height: 0;
         opacity: 0;
      }
      to {
         height: 40px;
         opacity: 1;
      }
   }
}

.filterBottomHidden {
   display: flex;
   margin-top: 5px;
   column-gap: 10px;
   align-items: center;
   justify-content: flex-start;
   // gap: 20px;
   opacity: 1;
   height: 0;
   overflow: hidden;
   transition: all 0.2s ease-in;
   animation: hideFilter 0.5s;
   opacity: 0;
   & > div {
      flex: 0 0 130px;
   }

   @keyframes hideFilter {
      from {
         height: 40px;
      }
      to {
         height: 0px;
      }
   }
}
.filterBottomHidden > * {
   transition: all 0.2s ease-in;
   opacity: 0;
}

.filtersDate {
   label {
      font-size: 10px;
      color: #0532f0;
      font-weight: 600;
   }
}

.transactionsTable {
   thead th,
   tbody td {
      padding: 20px 0px 20px 10px !important;
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
         text-align: center;
      }

      &:last-child {
         color: #b8bcce;
      }
   }

   button {
      width: fit-content !important;
   }

   .status {
      span {
         width: fit-content;
         padding: 4px 10px;
         height: 20px !important;
         text-align: center;
         position: relative !important;
         left: 0px !important;
         border-radius: 4px !important;
      }
   }

   .canceled {
      span {
         background-color: #e1e7f8;
      }
   }

   .failed {
      span {
         background-color: rgba(#e83f3f, 0.3);
         color: #e83f3f;
      }
   }
   .success {
      span {
         background-color: rgba(#07cc03, 0.3);
         color: #07cc03;
      }
   }

   .pending {
      span {
         background-color: rgba(#ff6600, 0.2);
         color: #ff6600;
      }
   }
   .delivered {
      span {
         background-color: rgba(#2cba00, 0.2);
         color: #25336b;
      }
   }
   .excluded {
      span {
         background-color: #e1e7f8;
         color: #25336b;
      }
   }
}
.messageWrap {
   margin: 30px 0;
   background-color: white;
   padding: 20px 10px 20px 10px;
   box-shadow: 0 12px 32px 0 rgba(6, 51, 239, 0.1);
   border-radius: 12px;
   width: 100%;
   font-size: 12px;
}

.lowOpacityTimeDate {
   font-size: 9pt;
   padding-top: 5px;
   font-weight: 600;
}

.customDatepicker {
   width: 165px;
   height: 35px;
   outline: none;
   padding: 0px 15px;
   transition: all 0.2s ease;
   border: solid 2px #dcdee7;
   box-shadow: none;
   border-radius: 6px;
   font-size: 12px;
   color: #25316a;
   font-weight: 400;
}

.dateWrapper {
   position: relative;
}

.flagCell {
   display: flex;

   img {
      margin-top: 0px;
   }

   div {
      padding: 5px;
   }
}

.bundleTransactionTable {
   background-color: #f8f8f8;

   td {
      &:last-child {
         text-align: left !important;
      }
   }
}

.bundleHeader {
   td {
      font-weight: 600 !important;

      &:nth-child(n-1) {
         text-align: left !important;
      }
   }
}

.arrowIcon path {
   fill: #0532f0 !important;
}

.arrowIcon {
   width: 8px;
   cursor: pointer;
   transform: rotate(90deg);
   transition: all 0.3s;
}

td.active .arrowIcon {
   transform: rotate(90deg) rotateY(180deg);
   transition: all 0.3s;
}

td.active {
   border-left: 3px solid #0532f0;
}

.failedMsg {
   background-color: rgba(#e83f3f, 0.3);
   color: #e83f3f;
}
.successMsg {
   background-color: rgba(44, 186, 0, 0.2);
   color: #25336b;
}
