@import '../../assets/styles//utils/mixins';

.header {
   position: fixed;
   width: 100%;
   height: 80px;
   margin: 0 auto;
   padding: 0;
   padding-left: 120px;
   z-index: 100;
   -webkit-backdrop-filter: blur(10px);
   backdrop-filter: blur(10px);
   box-shadow: 0 4px 20px 0 rgba(37, 49, 106, 0.08);
   background-color: rgba(255, 255, 255, 0.95);
   display: flex;
   align-items: center;
   justify-content: space-between;
   h1 {
      font-size: 18px;
      font-weight: 600;
   }
   h4 {
      font-size: 12px;
      font-weight: 500;
      color: #ff6600;
   }

   .topNavLabel {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      // gap: 7px;
      width: 100%;
      height: 100%;
      max-width: 1450px;

      .topBarIcon {
         height: 100%;
         width: 80px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-right: 2px solid #dddfe5;
         border-left: 2px solid #dddfe5;
      }
   }
}
